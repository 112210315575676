import React from 'react';
import ReactDOM from 'react-dom/client';
import ZoomVideo from '@zoom/videosdk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'
import ZoomContext from './context/zoom-context.ts'

let meetingArgs = {};
let temp = new URLSearchParams(window.location.search);
temp.forEach((value,key) => {
   switch(key) {
      case "tk": 
        meetingArgs.token = value;
        break;
      case "pw":
        meetingArgs.password = value;
        break;
      case "se":
        meetingArgs.session = value;
        break;
      case "u":
        meetingArgs.user = value;
        break;
      case "rf":
        meetingArgs.opener = value;
        break;
   }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
const zmClient = ZoomVideo.createClient();
console.log(meetingArgs);
root.render(
  <React.StrictMode>
    <ZoomContext.Provider value={zmClient}>
    <App meetingArgs={meetingArgs}/>
    </ZoomContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
