import React, { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import "../node_modules/antd/dist/antd.min.css";
import produce from 'immer';

import Chat from './feature/chat/chat.tsx'

import ZoomContext from './context/zoom-context.ts';
import ZoomMediaContext from './context/media-context.ts';
import ChatContext from './context/chat-context.ts';
import LoadingLayer from './components/loading-layer.tsx';

import './App.css';

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

function App(props) {

  const { meetingArgs } = props;
  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState(false);
  const [status, setStatus] = useState('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState(null);
  const [chatClient, setChatClient] = useState(null);
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

  const zmClient = useContext(ZoomContext);
  let referrer = meetingArgs.opener;
  
  useEffect(() => {
    const init = async () => {
      console.log(`${window.location.origin}/lib`);
      await zmClient.init('en-US', `${window.location.origin}/lib`, {
        webEndpoint: "zoom.us",
        enforceMultipleVideos: false,
        enforceVirtualBackground: false,
        stayAwake: true
      });
      
      try {
        setLoadingText('Joining the session...');
        console.log(meetingArgs.session);
        console.log(meetingArgs.password);
        console.log(meetingArgs.token);
        console.log(meetingArgs.user);
        await zmClient.join(meetingArgs.session, meetingArgs.token, meetingArgs.user, meetingArgs.password)//.catch((e) => {
        //   console.log(e);
        //   //throw e;
        // });
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);
        const chatClient = zmClient.getChatClient();
        setChatClient(chatClient);
        setIsLoading(false);
        window.opener?.postMessage({closed: "Joined", message: "Joined Session"}, referrer)
      } 
      catch (e) {
        setIsLoading(false);
        message.error(e.reason);
        window.opener?.postMessage({ closed: "Exception", message: e.reason}, referrer);
        (window.opener) ? window.close() : console.log("no opener");
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [zmClient, meetingArgs]);

  const onConnectionChange = useCallback(
    (payload) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        console.log('getSessionInfo', zmClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host'
          });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onDialoutChange = useCallback((payload) => {
    console.log('onDialoutChange', payload);
  }, []);

  const onAudioMerged = useCallback((payload) => {
    console.log('onAudioMerged', payload);
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === 'closed') {
      setIsLoading(true);
      await zmClient.join(meetingArgs.session, meetingArgs.token, meetingArgs.user, meetingArgs.password);
      setIsLoading(false);
    } else if (status === 'connected') {
      await zmClient.leave();
      message.warn('You have left the session.');
      if(window.opener) {        
        window.opener?.postMessage({closed: "Left", message: "Left Session"}, referrer);
        window.close();
      }
    }
  }, [zmClient, status, meetingArgs]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      if(window.opener) {        
        window.opener?.postMessage({closed: "Closed", message: "Window Closed"}, referrer);
      }
    })},[])

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    zmClient.on('dialout-state-change', onDialoutChange);
    zmClient.on('merged-audio', onAudioMerged);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('dialout-state-change', onDialoutChange);
      zmClient.off('merged-audio', onAudioMerged);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

  return (
    <div className="App">
      {loading && <LoadingLayer content={loadingText} />}
      {!loading && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <ChatContext.Provider value={chatClient}>
            <Chat referrer={referrer} />
          </ChatContext.Provider>
        </ZoomMediaContext.Provider>
      )}
    </div>
  );
}

export default App;
