import React, { useState, useCallback, useContext, useEffect, MutableRefObject } from 'react';
import classNames from 'classnames';
import { message } from 'antd';
import ZoomContext from '../../../context/zoom-context.ts';
import RecordingContext from '../../../context/recording-context.ts';
import ZoomMediaContext from '../../../context/media-context.ts';
import { useUnmount, useMount } from '../../../hooks/useUnmount.ts';
import './chat-footer.scss';
import { isAndroidOrIOSBrowser } from '../../../utils/platform.ts';
import {
  MobileVideoFacingMode
} from '@zoom/videosdk';
import { LeaveButton } from './leave.tsx';
import { current } from 'immer';
interface ChatFooterProps {
  className?: string;
  shareRef?: MutableRefObject<HTMLCanvasElement | null>;
  sharing?: boolean;
  referrer?: string;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const ChatFooter = (props: ChatFooterProps) => {
  const { className, shareRef, sharing, referrer } = props;
  
  const { mediaStream } = useContext(ZoomMediaContext);
  
  const zmClient = useContext(ZoomContext);
  
  const onLeaveClick = useCallback(async () => {
    console.log('onLeaveClick');
    if(window.opener) {
      window.opener?.postMessage({closed: "Left", message: "Left Session"}, referrer)
      window.close();
    }
    await zmClient.leave();
    if(window.opener)
      window.close()
  }, [zmClient]);

  const onEndClick = useCallback(async () => {
    console.log('onEndClick');
    if(window.opener) {
      window.opener?.postMessage({closed: "Ended", message: "Ended Session"}, referrer);
      window.close();
    }
    await zmClient.leave(true);
    if(window.opener)
      window.close()
  }, [zmClient]);

  useEffect(() => {
    return () => {
    };
  }, [
    zmClient,
  ]);
  useUnmount(() => {
  });
  useMount(() => {
    if (mediaStream) {
      if (isAndroidOrIOSBrowser()) {
      }
    }
  });
  useEffect(() => {
    if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
    }
    return () => {
      if (zmClient.getSessionInfo().isInMeeting) {
      }
    };
  }, [mediaStream, zmClient]);
  return (
    <div className={classNames('video-footer', className)}>
      <LeaveButton onLeaveClick={onLeaveClick} isHost={zmClient.isHost()} onEndClick={onEndClick} />
    </div>
  );
};
export default ChatFooter;
